$(document).foundation();
$(document).ready(function () {
    initNavigation();
    initSliders();
    initForms();
});

function initNavigation() {
    // change header style when navigation sticks or unsticks
    $('#navigation')
        .on('sticky.zf.stuckto:top', function (e) {
            $('#header').addClass('header--stuck');
        })
        .on('sticky.zf.unstuckfrom:top', function (e) {
            $('#header').removeClass('header--stuck');
        });
}

function initSliders() {
    $('[data-slick]').slick({
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-chevron-thin-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-chevron-thin-right"></i></button>'
    });
}

function initForms() {
    $('.form form').validate();
}